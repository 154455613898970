<template>
  <div class="Assessment h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.assessment.Label }}
      </h3>
      <div
        class="row row-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="row py-5">
          <div class="row">
            <div class="col-md-8 pt-2 pb-2 pb-md-5">
              <div
                class="card shadow"
                data-aos="zoom-out"
                data-aos-easing="ease-in-out"
                data-aos-duration="600"
              >
                <div class="card-body p-3 p-md-5">
                  <span v-html="this.assessment.initial_assessment"></span>
                </div>
              </div>
            </div>
            <div
              class="col-md-4 pb-5 pb-md-5"
              data-aos="fade-left"
              data-aos-easing="ease-in-out"
              data-aos-duration="600"
            >
              <img
                src="~@/assets/images/initial_assessment.jpg"
                class="w-100 rounded"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Initial Assessment",
  data() {
    return {
      assessment: [],
    };
  },
  methods: {
    getAssessment() {
      this.assessment = this.$store.getters["assessment"];
    },
  },
  mounted() {
    this.getAssessment();
    this.$store.dispatch('prevNavigationStatus', true)
    this.$store.dispatch( 'setInstructions', this.$store.getters['assessment'].instructions )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style>
</style>